import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { env } from '../index.js'
import EmailSignGenerator from './EmailSignGenerator'
import version from '../env/version.json'

export default function MainPage(props) {
  return (
    <div>
      <MenuAppBar env={env} />
      <BrowserRouter>
        <Routes>
          <Route path='' element={<EmailSignGenerator />} />
          <Route path='/version' element={<div>{version.version}</div>} />
          <Route path='/*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
