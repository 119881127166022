import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import MainPage from './components/MainPage'
import axios from 'axios'
import { CenteredDiv, WtxColors, SetupProvider, createWtxAuthInstance } from '@wavetronix/common-components'
import generateEnv from './env/generateEnv.js'
import { english as en } from './lang/en.js'
import { french as fr } from './lang/fr.js'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const root = ReactDOM.createRoot(document.getElementById('root'))

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <React.StrictMode>
      <SetupProvider
        env={env}
        msalInstance={msalInstance}
        allowedRoles={['Wavetronix Employee']}
        languages={{
          en: en,
          fr: { ...fr }
        }}>
        <MainPage />
      </SetupProvider>
    </React.StrictMode>
  )
}
